<template>
  <main>
    <menu-tab :menu="menu" :slidesPerView="1" :initIndex="type" @change="selectMenu"></menu-tab>
    <!--<div class="order-non">
      订单编号：{{ono}}
    </div>-->
    <ui-form
            v-model:content="content"
            v-model:file="files"
    ></ui-form>
    <ui-button class="submit" type="submit" @handleclick="submitTopic()">保存</ui-button>
    <loading :options="loadOption" v-if="loading"></loading>
  </main>
</template>


<script lang="ts">
import { defineComponent, ref } from "vue";
import MenuTab from "@/components/common/MenuTab.vue";
import UiForm from "@/components/common/QuestionForm.vue";
import UiButton from "@/components/UI/Button";
import Loading from "@/components/UI/Loading/src/Loading.vue";
import { useRoute, useRouter} from 'vue-router'
import axios from "@/api/axios";
import Toast from "@/components/UI/Toast";
import qs from "qs";
import router from "@/router";
export default defineComponent({
  name: "ApplySaleOrder",
  components: {
    MenuTab,
    UiForm,
    UiButton,
    Loading,
  },
  setup() {
    //  切换列表菜单
    type Menu = "提交售后";
    const menu: Menu[] = ["提交售后"];
    const route = useRoute()
    const type = Number(route.query.type || 0);
    const ono = route.query.ono;
    const contact = route.query.contact;
    const concatTel = route.query.concatTel;

    const currentMenuItem = ref(menu[Number(type)]);
    function selectMenu(item: Menu) {
      currentMenuItem.value = item;
    }
    //
    const content = ref("");
    const files = ref([]);
    const imageReg = /image\/(.+)/;
    const videoReg = /video\/(.+)/;
    function upload(files: any[]) {
      return new Promise((resolve, reject) => {
        const fileEmpty = files.length === 0;
        let url = "";
        let filePath = "",
            videos = "",
            isImg = false,
            isVideo = false;

        if (fileEmpty) {
          resolve({ filePath, videos });
        }
        const file = files[0].file;
        if (imageReg.test(file.type)) {
          url = "/M/FroalaApi/UploadImage";
          isImg = true;
        }
        if (videoReg.test(file.type)) {
          url = "/M/FroalaApi/UploadVideo";
          isVideo = true;
        }
        const formData = new FormData();
        files.forEach((item) => {
          formData.append("file", item.file);
        });
        try {
          axios
            .post(url, formData, {
              timeout: 0,
            })
            .then((res) => {
              if (res.data.success) {
                if (isImg) {
                  filePath = res.data.returnUrl;
                }
                if (isVideo) {
                  videos = res.data.returnUrl;
                }
                resolve({ filePath, videos });
              }
            });
        } catch (e) {
          Toast({
            type: "error",
            title: Object.prototype.toString.call(e),
          });
        }
      });
    }

    const loading = ref(false);
    const loadOption = {
      text: "正在上传...",
      color: "#b9135a",
      textColor: "#b9135a",
    };
    function submitTopic() {
      if (content.value == "") {
        Toast({
          title: "请输入相关内容！",
          type: "warning",
        });
        return false;
      }
      loading.value = true;
      upload(files.value).then((res: any) => {
        const data = qs.stringify({
          contents: content.value,
          ono: ono,
          contact: contact,
          concatTel: concatTel,
          ...res,
        });
        axios
          .post("/M/salesOrder/doSaveSalesOrder?rnd=", data)
          .then((res) => {
            if (res.data.success) {
              loading.value = false;
              Toast({
                type: "success",
                title: res.data.msg,
                onClose: () => {
                  router.push({
                    name: "Order",
                    query: {
                      type: 2,
                    },
                  });
                },
              });
            } else {
              loading.value = false;
              Toast({
                type: "error",
                title: res.data.msg,
              });
            }
          })
          .catch((err) => {
            loading.value = false;
            console.log("err");
          });
      });
    }
    return {
      content,
      files,
      ono,
      submitTopic,
      loading,
      loadOption,
      menu,
      type,
      currentMenuItem,
      selectMenu,
    };
  },
});
</script>

<style lang="scss" scoped>
.order-non{
  font-size: 14px;
  padding: 10px 0px 0px 20px;
  margin-bottom: -16px;
}
</style>
